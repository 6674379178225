import { Field, RemoveEntityButton, TableCell } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../../components'

export const genericPageTableBody = (
	<>
		<TableCell>
			<Field field="title" />
		</TableCell>
		<TableCell shrunk>
			<EditButton pageName="pageEdit" />
		</TableCell>
		<TableCell shrunk>
			<RemoveEntityButton removalType="delete" immediatePersist={true} />
		</TableCell>
	</>
)
