import { CreatePage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { SiteField } from '../components'
import { PubForm, PubFormSide } from '../forms'

export const PubCreatePage = (
	<CreatePage
		entity="Pub"
		pageName="pubCreate"
		rendererProps={{
			title: 'Add a new pub',
			side: <PubFormSide />,
			navigation: <NavigateBackButton to="pubList">Pubs</NavigateBackButton>,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: 'pubList' })}
	>
		<SiteField />
		<PubForm />
	</CreatePage>
)
