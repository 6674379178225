import { EditPage, Field, PageLinkButton, Repeater } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../components'

export const CategoryPage = (
	<EditPage
		pageName="categories"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Categories',
			actions: <PageLinkButton to="categoryCreate">Add a new category</PageLinkButton>,
		}}
	>
		<Repeater sortableBy="order" field="categories" removalType="delete" enableAddingNew={false} label={undefined}>
			<Field field="name" />
			<div style={{ float: 'right' }}>
				<EditButton pageName="categoryEdit" />
			</div>
		</Repeater>
	</EditPage>
)
