import {
	Block,
	BlockRepeater,
	BlockType,
	CheckboxField,
	Component,
	DiscriminatedBlocks,
	HasOne,
	LineBreakBehavior,
	Literal,
	Mark,
	Repeater,
	RichTextField,
	SelectField,
	TextAreaField,
	TextField,
	VideoUploadField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../components'

export const GenericContentForm = Component(
	() => (
		<>
			<BlockRepeater
				sortableBy="order"
				field="content.blocks"
				removalType="delete"
				discriminationField="type"
				label="Content"
			>
				<Block discriminateBy="contentTextSection" label="Text section">
					<RichTextField
						label="Text"
						field="text"
						lineBreakBehavior={LineBreakBehavior.SMART}
						blocks={[
							{ block: BlockType.PARAGRAPH, marks: [Mark.BOLD, Mark.LINK, Mark.ITALIC] },
							{ block: BlockType.HEADING_H2, marks: [] },
							{ block: BlockType.HEADING_H3, marks: [] },
						]}
					/>
				</Block>
				<Block discriminateBy="contentImage" label="Image">
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="contentHtml" label="HTML">
					<TextAreaField field="text" label="Text" />
				</Block>
				<Block discriminateBy="contentGallery" label="Desktop gallery">
					<HasOne field="gallery">
						<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
							<ImageField field="image" label="Image" />
							<TextField field="caption" label="Caption" />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="frontTextBox" label="Text box">
					<TextField field="title" label="Title" allowNewlines />
					<TextField field="subtitle" label="Subtitle" allowNewlines />
					<TextAreaField field="text" label="Text" />
				</Block>
				<Block discriminateBy="contestMedia" label="Contest media">
					<TextField field="title" label="Title" />
					<TextAreaField field="text" label="Text" />
					<TextField field="url" label="Button link" />
					<TextField field="buttonCaption" label="Button caption" />
					<TextAreaField field="secondaryText" label="Secondary text" />
					<TextField field="subtitle" label="Hashtag text" />
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="modal" label="Modal">
					<TextField field="title" label="Title" />
					<TextAreaField field="text" label="Text" />
					<TextAreaField field="secondaryText" label="ID" />
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="hero" label="Hero" description="Image covered by a text">
					<DiscriminatedBlocks field="heroView" label="View">
						<Block discriminateBy="normal" label="Normal">
							<></>
						</Block>
						<Block discriminateBy="discoveries" label="Discoveries">
							<ImageField field="secondaryImage" label="Logo" />
						</Block>
					</DiscriminatedBlocks>
					<TextField field="title" label="Title" allowNewlines />
					<TextAreaField field="text" label="Desc" />
					<TextField field="buttonCaption" label="Button text" />
					<TextField field="url" label="Button link" />
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="videoTiles" label="Video tiles">
					<HasOne field="videos">
						<Repeater field="items" label="Video" sortableBy="order">
							<TextField field="smallHeadline" label="Small headline" />
							<TextField field="whiteHeadline" label="White headline" />
							<TextField field="beigeHeadline" label="Beige headline" />
							<TextField field="videoUrl" label="Video url" />
							<ImageField field="image" label="Image" />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="vouchersMap" label="Vouchers map">
					<TextField field="title" label="Title" allowNewlines />
					<TextAreaField field="text" label="Desc" />
					<HasOne field="list">
						<Repeater sortableBy="order" field="items" removalType="delete" label={undefined}>
							<TextField field="text" label="Text" />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="relatedTapsters" label="Related tapsters">
					<HasOne field="tapsterSet">
						<Repeater sortableBy="order" field="tapsters" removalType="delete" label={undefined}>
							<SelectField field="tapster" label="Tapster" options="Tapster[site.slug=$site].name" />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="relatedPosts" label="Related posts">
					<HasOne field="postSet">
						<Repeater sortableBy="order" field="posts" removalType="delete" label={undefined}>
							<SelectField field="post" label="Post" options="Post[site.slug=$site].title" />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="videos" label="Videos">
					<HasOne field="gallery">
						<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
							<VideoUploadField field="video.url" label="Video URL" />
							<TextField field="video.externalUrl" label="Video external url" />
							<ImageField field="image" label="Image" />
							<TextField field="caption" label="Caption" allowNewlines />
							<TextField field="subtitle" label="Subtitle" />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="frontPhoneGallery" label="Front phone gallery">
					<HasOne field="gallery">
						<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
							<ImageField field="image" label="Image" single />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="contest" label="Contest">
					<TextField field="title" label="Title" />
					<TextField field="subtitle" label="Title second line" />
					<TextField field="text" label="Text" allowNewlines />
					<TextField field="secondaryText" label="Success text" allowNewlines />
					<HasOne field="contestFields">
						<TextField field="firstNameCaption" label="First name" />
						<TextField field="lastNameCaption" label="Last name" />
						<TextField field="birthCaption" label="Birth" />
						<TextField field="mobileCaption" label="Mobile caption" />
						<TextField field="emailCaption" label="Email caption" />
						<TextField field="textareaCaption" label="Textarea" />
						<TextField field="sendButtonCaption" label="Send" />
						<TextField field="conditionsCaption" label="Conditions" />
					</HasOne>
				</Block>
				<Block discriminateBy="socials" label="Socials">
					<TextField field="title" label="Title" allowNewlines />
					<HasOne field="socialFields">
						<TextField field="youtubeLink" label="Youtube" />
						<TextField field="instagramLink" label="Instagram" />
						<TextField field="facebookLink" label="Facebook" />
					</HasOne>
					<HasOne field="gallery">
						<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
							<ImageField field="image" label="Image" single />
							<TextField field="link" label="Link" />
						</Repeater>
					</HasOne>
					<TextField field="subtitle" label="Conditions link" />
					<TextField field="text" label="Conditions caption" />
					<TextField field="url" label="Privacy link" />
					<TextField field="secondaryText" label="Privacy caption" />
				</Block>
				<Block discriminateBy="frontDesktopGallery" label="Front desktop gallery">
					<HasOne field="gallery">
						<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
							<ImageField field="image" label="Image" single />
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="videoBox" label="Cover video">
					<TextAreaField field="text" label="Text" />
					<VideoUploadField field="video.url" label="Video" />
					<TextField field="video.url" label="Video URL" />
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="centerTextBox" label="Center text box">
					<TextField field="title" label="Title" />
					<TextAreaField field="text" label="Subtitle" />
					<TextAreaField field="secondaryText" label="Description" />
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="bottles" label="Bottles">
					<TextField field="title" label="Title" />
					<HasOne field="bottles">
						<Repeater field="items" label="Bottles" sortableBy="order">
							<TextField field="title" label="Title" />
							<ImageField field="image" label="Image" />
							<HasOne field="features">
								<Repeater field="items" label="Bottle captions" sortableBy="order">
									<ImageField field="image" label="Image" />
									<TextAreaField field="text" label="Text" />
								</Repeater>
							</HasOne>
						</Repeater>
					</HasOne>
				</Block>
				<Block discriminateBy="coverVideo" label="Cover video with text">
					<TextField field="title" label="Title" allowNewlines />
					<TextAreaField field="text" label="Text" />
					<VideoUploadField field="video.url" label="Video" />
					<TextField field="video.url" label="Video URL" />
					<ImageField field="image" label="Image" />
					<ImageField field="secondaryImage" label="Poster" />
				</Block>
				<Block discriminateBy="frontHalfImageLeft" label="Image with text half split left">
					<ImageField field="secondaryImage" label="Text image/logo" />
					<TextField field="title" label="Title" allowNewlines={true} />
					<TextAreaField field="text" label="Text" />
					<TextAreaField field="secondaryText" label="Secondary text" />
					<ImageField field="image" label="Image" />
					<TextField field="buttonCaption" label="Button caption" allowNewlines={true} />
					<TextField field="url" label="Button url" />
					<CheckboxField field={'showSocialLinks'} label={'Show social links'} />
					<CheckboxField field={'halfboxFull'} label={'Full width'} />
					<SelectField
						field="halfboxView"
						label="View"
						options={[
							{ value: new Literal('beige_particles'), label: 'Beige with particles' },
							{ value: new Literal('white'), label: 'White' },
							{ value: new Literal('green_particles'), label: 'Green with particles' },
							{ value: new Literal('default'), label: 'Default' },
						]}
					/>
				</Block>
				<Block discriminateBy="frontHalfImageRight" label="Image with text half split right">
					<ImageField field="secondaryImage" label="Text image/logo" />
					<TextField field="title" label="Title" allowNewlines={true} />
					<TextAreaField field="text" label="Text" />
					<TextAreaField field="secondaryText" label="Secondary text" />
					<ImageField field="image" label="Image" />
					<TextField field="buttonCaption" label="Button caption" allowNewlines={true} />
					<TextField field="url" label="Button url" />
					<CheckboxField field={'showSocialLinks'} label={'Show social links'} />
					<CheckboxField field={'halfboxFull'} label={'Full width'} />
					<SelectField
						field="halfboxView"
						label="View"
						options={[
							{ value: new Literal('beige_particles'), label: 'Beige with particles' },
							{ value: new Literal('white'), label: 'White' },
							{ value: new Literal('green_particles'), label: 'Green with particles' },
							{ value: new Literal('default'), label: 'Default' },
						]}
					/>
				</Block>
			</BlockRepeater>
		</>
	),
	'GenericContentForm',
)
