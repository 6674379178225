import {
	Box,
	ButtonList,
	Component,
	DesugaredRelativeSingleEntity,
	Entity,
	EntityAccessor,
	Field,
	getRelativeSingleEntity,
	HasMany,
	HasOne,
	Message,
	PageLinkButton,
	RemoveEntityButton,
	Table,
	TableCell,
	TableRow,
	useEntityContext,
	useProjectUserRoles,
	useRelativeEntityList,
} from '@contember/admin'
import * as React from 'react'
import { UserRoles } from '../../acl'
import { genericPageTableBody } from './GenericPageTableBody'

const customPages: Array<{
	field: DesugaredRelativeSingleEntity
	title: React.ReactNode
	editPageName: string
}> = [
	{
		field: {
			hasOneRelationPath: [{ field: 'hopsPage', filter: undefined, reducedBy: undefined }],
		},
		title: 'Hops page',
		editPageName: 'hops',
	},
	{
		field: {
			hasOneRelationPath: [{ field: 'pourPage', filter: undefined, reducedBy: undefined }],
		},
		title: 'Pour page',
		editPageName: 'pour',
	},
]

export const GenericPageList = Component(
	() => {
		const userRoles = useProjectUserRoles()
		const genericPages = useRelativeEntityList('genericPages')
		const siteEntity = useEntityContext()

		const isAdmin = userRoles.has(UserRoles.Admin)
		const isRegionManager = userRoles.has(UserRoles.RegionManager)

		const shouldDisplayCustomPages =
			isAdmin ||
			(isRegionManager &&
				customPages.reduce<boolean>((accumulator, customPage) => {
					const currentEntity = getRelativeSingleEntity(siteEntity, customPage.field)
					return accumulator || currentEntity.isPersisted()
				}, false))

		const shouldDisplayGenericPages = genericPages.entities.reduce<boolean>(
			(accumulator, datum) => accumulator || (datum instanceof EntityAccessor && datum.isPersisted()),
			false,
		)

		return (
			<>
				{shouldDisplayCustomPages && (
					<Table>
						{customPages.map((customPage, i) => {
							const currentEntity = getRelativeSingleEntity(siteEntity, customPage.field)

							if (!isAdmin && !currentEntity.isPersisted()) {
								return <React.Fragment key={i}>{null}</React.Fragment>
							}
							return (
								<TableRow key={i}>
									<TableCell>{customPage.title}</TableCell>
									{isAdmin && !currentEntity.isPersisted() && (
										<TableCell>
											<Message size="small">This page does not yet exist for this market.</Message>
										</TableCell>
									)}
									<TableCell shrunk>
										<ButtonList>
											<PageLinkButton to={customPage.editPageName}>
												{currentEntity.isPersisted() && 'Edit'}
												{!currentEntity.isPersisted() && 'Create'}
											</PageLinkButton>
											{isAdmin && currentEntity.isPersisted() && (
												<HasOne field={customPage.field.hasOneRelationPath}>
													<RemoveEntityButton removalType="delete" immediatePersist={true} />
												</HasOne>
											)}
										</ButtonList>
									</TableCell>
								</TableRow>
							)
						})}
					</Table>
				)}
				{!shouldDisplayGenericPages && (
					<Box>
						{shouldDisplayCustomPages && (
							<Message
								flow="generousBlock"
								action={
									<PageLinkButton to="pageCreate" size="small">
										Add a new page
									</PageLinkButton>
								}
							>
								There are no generic pages.
							</Message>
						)}
						{!shouldDisplayCustomPages && <Message flow="generousBlock">There are no pages.</Message>}
					</Box>
				)}
				{shouldDisplayGenericPages && genericPages && (
					<Table>
						{genericPages.getFilteredEntities().map(entity => (
							<Entity key={entity.getKey()} accessor={entity}>
								<TableRow>{genericPageTableBody}</TableRow>
							</Entity>
						))}
					</Table>
				)}
			</>
		)
	},
	() => (
		<>
			{customPages.map((customPage, i) => (
				<HasOne field={customPage.field.hasOneRelationPath} key={i}>
					<Field field="id" />
				</HasOne>
			))}
			<HasMany field="genericPages">{genericPageTableBody}</HasMany>
		</>
	),
	'GenericPageList',
)
