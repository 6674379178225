import { CreatePage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { SiteField } from '../components'
import { PromoPubForm, PromoPubFormSide } from '../forms'

export const PromoPubCreatePage = (
	<CreatePage
		entity="PromoPub"
		pageName="promoPubCreate"
		rendererProps={{
			title: 'Add a new campaign pub',
			side: <PromoPubFormSide />,
			navigation: <NavigateBackButton to="promoPubList">Campaign pubs</NavigateBackButton>,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: 'promoPubList' })}
	>
		<SiteField />
		<PromoPubForm />
	</CreatePage>
)
