import {
	Block,
	BlockRepeater,
	Component,
	HasOne,
	Repeater,
	TextAreaField,
	TextField,
	VideoUploadField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../components'

export const FrontPageContentForm = Component(
	() => (
		<BlockRepeater
			sortableBy="order"
			field="content.blocks"
			discriminationField="type"
			removalType="delete"
			label={undefined}
		>
			<Block discriminateBy="frontTextBox" label="Text box">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextField field="subtitle" label="Subtitle" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<TextAreaField field="url" label="Button url" />
				<TextAreaField field="buttonCaption" label="Button text" />
			</Block>
			<Block discriminateBy="frontVideo" label="Text box">
				<VideoUploadField field="video.url" label="Video" />
				<TextField field="video.url" label="Video URL" />
				<ImageField field="image" label="Image" />
				<ImageField field="secondaryImage" label="Poster" />
			</Block>
			<Block discriminateBy="frontHalfImageLight" label="'Light' image with text half split">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<ImageField field="image" label="Image" />
				<TextField field="buttonCaption" label="Button caption" allowNewlines={true} />
				<TextField field="url" label="Button url" />
			</Block>
			<Block discriminateBy="frontHalfImageLeft" label="Image with text half split left">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<ImageField field="image" label="Image" />
			</Block>
			<Block discriminateBy="frontHalfImageRight" label="Image with text half split right">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<ImageField field="image" label="Image" />
			</Block>
			<Block discriminateBy="frontPhotoBoxLeft" label="Photo box left">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<ImageField field="image" label="Image" />
			</Block>
			<Block discriminateBy="frontPhotoBoxRight" label="Photo box right">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<ImageField field="image" label="Image" />
			</Block>
			<Block discriminateBy="frontLargeImage" label="Large photo">
				<ImageField field="image" label="Image" />
			</Block>
			<Block discriminateBy="frontLargeImageWithTextBox" label="Text and large photo">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextField field="subtitle" label="Subtitle" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<ImageField field="image" label="Image" />
			</Block>
			<Block discriminateBy="frontPhoneGallery" label="Phone gallery">
				<HasOne field="gallery">
					<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
						<ImageField field="image" label={undefined} single={true} />
					</Repeater>
				</HasOne>
			</Block>
			<Block discriminateBy="frontDesktopGallery" label="Desktop gallery">
				<HasOne field="gallery">
					<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
						<ImageField field="image" label={undefined} single={true} />
					</Repeater>
				</HasOne>
			</Block>
			<Block discriminateBy="frontCarousel" label="Carousel">
				<TextField field="title" label="Title" allowNewlines={true} />
				<TextAreaField field="text" label="Text" />
				<HasOne field="gallery">
					<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
						<TextAreaField field="caption" label="Caption" />
						<ImageField field="image" label="Image" />
					</Repeater>
				</HasOne>
			</Block>
		</BlockRepeater>
	),
	'FrontPageContentForm',
)
