import { CreatePage, HiddenField, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { SiteField } from '../components'
import { CategoryForm } from '../forms'

export const CategoryCreatePage = (
	<CreatePage
		entity="Category"
		pageName="categoryCreate"
		rendererProps={{
			title: 'Add a new category',
			navigation: <NavigateBackButton to="categories">Categories</NavigateBackButton>,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: 'categories' })}
	>
		<HiddenField field="order" defaultValue={99} label={undefined} />
		<SiteField />
		<CategoryForm />
	</CreatePage>
)
