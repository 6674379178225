import { Box, CheckboxList, Component, TextAreaField, TextField } from '@contember/admin'
import * as React from 'react'
import { ImageField, IsPublishedField, LinkUrlField } from '../components'
import { GenericContentForm, SeoForm } from './'

export const PostForm = Component(
	() => (
		<>
			<Box>
				<TextAreaField field="title" size="large" label="Title" />
				<LinkUrlField drivenBy="title" softPrefix="stories/" />
			</Box>
			<Box>
				<ImageField field="headerImage" label="Header image" />
				<ImageField field="listingImage" label="Listing image" />
				<TextField field="imageDescription" label="Image description" />
			</Box>
			<Box>
				<TextAreaField field="perex" label="Perex" />
			</Box>
			<GenericContentForm />
			<Box>
				<CheckboxList field="tags" options={`Tag[site.slug = $site].name`} label="Tags" />
			</Box>
			<Box>
				<CheckboxList field="categories" options={`Category[site.slug = $site].name`} label="Categories" />
			</Box>
			<SeoForm />
		</>
	),
	'PostForm',
)

export const PostFormSide = Component(() => <IsPublishedField />, 'PostFormSide')
