import { Field, PageLinkButton, TableCell, TablePage } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../components'

export const BlogListPage = (
	<TablePage
		pageName="blogList"
		entities="Post[site.slug = $site]"
		orderBy="publishedAt desc"
		rendererProps={{
			title: 'Stories',
			actions: (
				<>
					<PageLinkButton to="blogPage">Edit the Stories page</PageLinkButton>
					<PageLinkButton to="blogCreate">Add a new story</PageLinkButton>
				</>
			),
		}}
	>
		<TableCell>
			<Field field="title" />
		</TableCell>
		<TableCell>
			<div style={{ color: '#444', fontSize: '11px' }}>
				<Field
					field="publishedAt"
					format={val => {
						return val ? 'Published on ' + new Date(val as string).toLocaleDateString() : 'Not published'
					}}
				/>
			</div>
		</TableCell>
		<TableCell shrunk>
			<EditButton pageName="blogEdit" />
		</TableCell>
	</TablePage>
)
