import { Box, CheckboxList, Component, TextAreaField, TextField } from '@contember/admin'
import * as React from 'react'
import { ImageField, IsPublishedField, LinkUrlField, LocationField } from '../components'
import { AttributesForm, GenericContentForm, SeoForm } from './'

export const PubForm = Component(
	() => (
		<>
			<Box>
				<TextField field="name" label="Name" size="large" />
				<LinkUrlField drivenBy="name" softPrefix="pubs/" />
				<TextField field="subtitle" label="Subtitle" />
			</Box>

			<Box>
				<ImageField field="headerImage" label="Header image" />
				<ImageField field="listingImage" label="Listing image" />
				<TextField field="imageDescription" label="Image description" />
			</Box>

			<Box heading="Location">
				<TextField field="locationText" label="Text" />
				<LocationField field="location" label="Location" />
			</Box>
			<Box>
				<TextAreaField field="perex" label="Perex" />
			</Box>
			<AttributesForm field="attributeSet" />
			<GenericContentForm />
			<Box>
				<CheckboxList field="tags" options={`Tag[site.slug = $site].name`} label="Tags" />
			</Box>

			<SeoForm />
		</>
	),
	'PubForm',
)

export const PubFormSide = Component(() => <IsPublishedField />, 'PubFormSide')
