import { Box, Component, TextField, CheckboxField } from '@contember/admin'
import * as React from 'react'
import { LinkUrlField } from '../components'
import { GenericContentForm, SeoForm } from './'

export const GenericPageForm = Component(
	() => (
		<>
			<Box>
				<TextField field="title" label="Title" size="large" />
				{/*<LinkUrlField drivenBy="title" />*/}
				<TextField field="link.url" label="URL" />
				<TextField field="view" label="View identifier" />
			</Box>
			<CheckboxField field={'fullWidth'} label={'Full width'} />
			<GenericContentForm />
			<SeoForm />
		</>
	),
	'GenericPageForm',
)
