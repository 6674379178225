import { Block, Box, DiscriminatedBlocks, EditPage, HasOne, Repeater, SelectField, TextField } from '@contember/admin'
import * as React from 'react'

export const FooterPage = (
	<EditPage
		pageName="footer"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Footer',
		}}
	>
		<HasOne field="footer">
			<Box heading="Contact us">
				<TextField field="contactLabel" label="Label" />
				<TextField field="contactLink" label="Link" />
			</Box>

			<Box heading="Copyright">
				<TextField field="copyright" label={undefined} />
				<TextField field="copyrightJap" label="JAP version only" allowNewlines={true} />
			</Box>

			<Box heading="Don't drink and drive">
				<TextField field="dontDriveSticky" label="Sticky" />
				<TextField field="dontDriveHeading" label="Main text" />
				<TextField field="dontDriveLink" label="Link" />
			</Box>

			<Repeater sortableBy="order" field="links" removalType="delete" label="Links">
				<TextField field="caption" label="Caption" />
				<DiscriminatedBlocks field="linkType" label={undefined}>
					<Block discriminateBy="external" label="External">
						<TextField field="linkUrl" label="URL" />
					</Block>
					<Block discriminateBy="internal" label="Internal">
						<SelectField field="link" label="Link" options="Linkable.url" />
					</Block>
				</DiscriminatedBlocks>
			</Repeater>
		</HasOne>
	</EditPage>
)
