import { EditPage, HasOne, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { LinkForm, SeoForm } from '../forms'

export const BlogPage = (
	<EditPage
		pageName="blogPage"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Stories page',
			navigation: <NavigateBackButton to="blogList">Stories</NavigateBackButton>,
		}}
	>
		<HasOne field="blogPage">
			<LinkForm />
			<SeoForm />
		</HasOne>
	</EditPage>
)
