import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { TagForm } from '../forms'

export const TagEditPage = (
	<EditPage
		entity="Tag(id = $id)"
		pageName="tagEdit"
		rendererProps={{
			title: 'Edit tag',
			navigation: <NavigateBackButton to="tags">Tags</NavigateBackButton>,
		}}
	>
		<TagForm />
	</EditPage>
)
