import { EditPage, HasOne, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { GenericContentForm, LinkForm, SeoForm } from '../forms'

export const HopsPage = (
	<EditPage
		pageName="hops"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Hops page',
			navigation: <NavigateBackButton to="pageList">Pages</NavigateBackButton>,
		}}
	>
		<HasOne field="hopsPage">
			<LinkForm />
			<GenericContentForm />
			<SeoForm />
		</HasOne>
	</EditPage>
)
