import { Box, Component, TextAreaField, TextField, CheckboxField } from '@contember/admin'

import * as React from 'react'
import { ImageField, IsPublishedField } from '../components'

export const PodcastForm = Component(
	() => (
		<>
			<Box>
				<CheckboxField field="isPinned" label="Pin this podcast" defaultValue={false} />
				<TextField field="name" label="Name" size="large" />
				<TextField field="subtitle" label="Subtitle" />
			</Box>

			<Box>
				<TextAreaField field="perex" label="Perex" />
			</Box>

			<Box>
				<TextField field="link" label="Link" />
				<TextField field="linkSecondary" label="Link secondary" />
			</Box>

			<Box>
				<ImageField field="image" label="Featured image" />
			</Box>
		</>
	),
	'PodcastForm',
)

export const PodcastFormSide = Component(() => <IsPublishedField />, 'PodcastFormSide')
