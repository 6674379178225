import { Component, EntityAccessor, Field, FieldAccessor, SelectField, SelectFieldProps } from '@contember/admin'
import * as React from 'react'

export type LocationFieldProps = Omit<SelectFieldProps, 'options'>

export const LocationField = Component(
	(props: LocationFieldProps) => (
		<SelectField
			{...(props as any)}
			options="Location[site.slug=$site]"
			renderOptionText={accessor => {
				const parent = accessor.getField('parent')
				let parentName = ''

				if (parent instanceof EntityAccessor) {
					parentName = (parent.getField('name') as FieldAccessor).currentValue as string
				}

				return `${(accessor.getField('name') as FieldAccessor).currentValue}${parentName ? `, ${parentName}` : ''}`
			}}
			optionFieldStaticFactory={
				<>
					<Field field="name" />
					<Field field="parent.name" />
				</>
			}
		/>
	),
	'LocationField',
)
