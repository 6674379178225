import { Field, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'

export const TranslationsPage = (
	<MultiEditPage
		pageName="translations"
		entities="Translatable"
		rendererProps={{ title: 'String translations', enableAddingNew: false, enableRemoving: true }}
	>
		<TextField
			label={
				<>
					Translation of "<Field field="identifier" />"
				</>
			}
			field="translations(site.slug=$site).value"
			allowNewlines={true}
		/>
	</MultiEditPage>
)
