import { Component, HasOne, Repeater, TextField } from '@contember/admin'
import * as React from 'react'

interface AttributesFormProps {
	field: string
}

export const AttributesForm = Component<AttributesFormProps>(
	props => (
		<>
			<HasOne {...props}>
				<Repeater sortableBy="order" field="attributes" label="Attributes">
					<TextField field="title" label="Title" />
					<Repeater sortableBy="order" field="values" label="Values">
						<TextField field="value" label="Value" />
						<TextField field="url" label="URL" />
					</Repeater>
				</Repeater>
			</HasOne>
		</>
	),
	'AttributesForm',
)
