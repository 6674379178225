import { Box, EditPage, HasOne, NavigateBackButton, TextField } from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../components'
import { LinkForm, SeoForm } from '../forms'

export const TapstersPage = (
	<EditPage
		pageName="tapstersPage"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Tapsters page',
			navigation: <NavigateBackButton to="tapsterList">Tapsters</NavigateBackButton>,
		}}
	>
		<HasOne field="tapstersPage">
			<Box>
				<TextField field="title" size="large" label="Title" allowNewlines={true} />
				<LinkForm />
			</Box>
			<ImageField field="headerImage" label="Header image" />
			<SeoForm />
		</HasOne>
	</EditPage>
)
