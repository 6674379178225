import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { LocationForm } from '../forms'

export const LocationEditPage = (
	<EditPage
		entity="Location(id = $id)"
		pageName="locationEdit"
		rendererProps={{
			title: 'Edit location',
			navigation: <NavigateBackButton to="locations">Locations</NavigateBackButton>,
		}}
	>
		<LocationForm />
	</EditPage>
)
