import { Field, PageLinkButton, TableCell, TablePage } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../components'

export const ContestSubmissionListPage = (
	<TablePage
		pageName="contestSubmissionList"
		entities="ContestSubmission"
		orderBy="createdAt"
		rendererProps={{
			title: 'Submissions',
		}}
	>
		<TableCell>
			<Field field="email" />
		</TableCell>
		<TableCell shrunk>
			<EditButton pageName="contestSubmissionEdit" />
		</TableCell>
	</TablePage>
)
