import { Box, Component, HasOne, TextAreaField, TextField } from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../components'

export interface SeoFormProps {}

export const SeoForm = Component<SeoFormProps>(
	props => (
		<Box heading="Page SEO">
			<HasOne field="seo">
				<TextField field="title" label="Title" />
				<TextAreaField field="description" label="Description" />
				<ImageField field="ogImage" label="OG image" single={true} />
				<TextField field="ogTitle" label="OG title" />
				<TextAreaField field="ogDescription" label="OG description" />
			</HasOne>
		</Box>
	),
	'SeoForm',
)
