import { Field, PageLinkButton, TableCell, TablePage } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../components'

export const PromoPubListPage = (
	<TablePage
		pageName="promoPubList"
		entities="PromoPub[site.slug = $site]"
		orderBy="publishedAt desc"
		rendererProps={{
			title: 'Campaign pubs',
			actions: (
				<>
					<PageLinkButton to="promoPubCreate">Add a new campaign pub</PageLinkButton>
				</>
			),
		}}
	>
		<TableCell>
			<Field field="name" />
		</TableCell>
		<TableCell>
			<Field field="subtitle" />
		</TableCell>
		<TableCell>
			<div style={{ color: '#444', fontSize: '11px' }}>
				<Field
					field="publishedAt"
					format={val => {
						return val ? 'Published on ' + new Date(val as string).toLocaleDateString() : 'Not published'
					}}
				/>
			</div>
		</TableCell>
		<TableCell shrunk>
			<EditButton pageName="promoPubEdit" />
		</TableCell>
	</TablePage>
)
