import { Box, EditPage, HasOne, NavigateBackButton, TextField } from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../components'
import { LinkForm, SeoForm } from '../forms'

export const PubsPage = (
	<EditPage
		entity="Site(slug = $site)"
		pageName="pubsPage"
		rendererProps={{
			title: 'Pubs page',
			navigation: <NavigateBackButton to="pubList">Pubs</NavigateBackButton>,
		}}
	>
		<HasOne field="pubsPage">
			<Box>
				<TextField field="title" size="large" label="Title" allowNewlines={true} />
				<LinkForm />
			</Box>
			<ImageField field="headerImage" label="Header image" />
			<SeoForm />
		</HasOne>
	</EditPage>
)
