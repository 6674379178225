import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { PodcastForm, PodcastFormSide } from '../forms'

export const PodcastEditPage = (
	<EditPage
		entity="Podcast(id = $id)"
		pageName="podcastEdit"
		rendererProps={{
			title: 'Edit podcast',
			side: <PodcastFormSide />,
			navigation: <NavigateBackButton to="podcastList">Podcasts</NavigateBackButton>,
		}}
	>
		<PodcastForm />
	</EditPage>
)
