import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { PubForm, PubFormSide } from '../forms'

export const PubEditPage = (
	<EditPage
		entity="Pub(id = $id)"
		pageName="pubEdit"
		rendererProps={{
			title: 'Edit pub',
			side: <PubFormSide />,
			navigation: <NavigateBackButton to="pubList">Pubs</NavigateBackButton>,
		}}
	>
		<PubForm />
	</EditPage>
)
