import { Box, EditPage, NavigateBackButton, TextAreaField, TextField } from '@contember/admin'
import * as React from 'react'

export const ContestSubmissionEditPage = (
	<EditPage
		entity="ContestSubmission(id = $id)"
		pageName="contestSubmissionEdit"
		rendererProps={{
			title: 'Edit submission',
			navigation: <NavigateBackButton to="contestSubmissionList">Submissions</NavigateBackButton>,
		}}
	>
		<Box>
			<TextField field="firstName" label="First name" />
			<TextField field="lastName" label="Last name" />
			<TextField field="birth" label="Birth" />
			<TextField field="mobile" label="Phone" />
			<TextField field="email" label="Email" />
			<TextField field="createdAt" label="Created at" />
			<TextAreaField field="text" label="Text" />
			<TextAreaField field="lang" label="Language" />
		</Box>
	</EditPage>
)
