import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { CategoryForm } from '../forms'

export const CategoryEditPage = (
	<EditPage
		entity="Category(id = $id)"
		pageName="categoryEdit"
		rendererProps={{
			title: 'Edit category',
			navigation: <NavigateBackButton to="categories">Categories</NavigateBackButton>,
		}}
	>
		<CategoryForm />
	</EditPage>
)
