import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { PromoPubForm, PromoPubFormSide } from '../forms'

export const PromoPubEditPage = (
	<EditPage
		entity="PromoPub(id = $id)"
		pageName="promoPubEdit"
		rendererProps={{
			title: 'Edit campaign pub',
			side: <PromoPubFormSide />,
			navigation: <NavigateBackButton to="promoPubList">Campaign pubs</NavigateBackButton>,
		}}
	>
		<PromoPubForm />
	</EditPage>
)
