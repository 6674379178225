import { Box, Component, FormGroupProps, HasOne, ImageUploadField } from '@contember/admin'
import * as React from 'react'

export interface ImageFieldProps {
	field: string
	single?: boolean
	label?: FormGroupProps['label']
}

export const ImageField = Component<ImageFieldProps>((props: ImageFieldProps) => {
	if (props.single) {
		return <ImageUploadField label={props.label} field={`${props.field}.url`} />
	}
	return (
		<Box heading={props.label} distinction="seamlessIfNested">
			<HasOne field={props.field}>
				<ImageUploadField label="Desktop" field="url" />
				<ImageUploadField label="Phone" field="phoneUrl" />
			</HasOne>
		</Box>
	)
})
