import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { PostForm, PostFormSide } from '../forms'

export const BlogEditPage = (
	<EditPage
		entity="Post(id = $id)"
		pageName="blogEdit"
		rendererProps={{
			title: 'Edit story',
			/*actions: ( // This does not work yet.
				<RemoveEntityButton intent="danger" size="small" immediatePersist={true}>
					Delete this story
				</RemoveEntityButton>
			),*/
			navigation: <NavigateBackButton to="blogList">Stories</NavigateBackButton>,
			side: <PostFormSide />,
		}}
	>
		<PostForm />
	</EditPage>
)
