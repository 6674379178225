import { EditPage, Literal, Repeater, SelectField, TextField } from '@contember/admin'
import * as React from 'react'

export const SocialLinkPage = (
	<EditPage
		pageName="social"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Social Networks',
		}}
	>
		<Repeater field="socialLinks" removalType="delete" label={undefined}>
			<SelectField
				field="network"
				label="Social network"
				//inline={true}
				options={[
					{ value: new Literal('facebook'), label: 'Facebook' },
					{ value: new Literal('twitter'), label: 'Twitter' },
					{ value: new Literal('youtube'), label: 'YouTube' },
					{ value: new Literal('instagram'), label: 'Instagram' },
				]}
			/>
			<TextField label="Url" field="url" />
		</Repeater>
	</EditPage>
)
