import { EditPage, HasOne, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { LinkForm, SeoForm } from '../forms'

export const PourPage = (
	<EditPage
		pageName="pour"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Pour page',
			navigation: <NavigateBackButton to="pageList">Pages</NavigateBackButton>,
		}}
	>
		<HasOne field="pourPage">
			<LinkForm />
			<SeoForm />
		</HasOne>
	</EditPage>
)
