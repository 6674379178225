import { CheckboxField, EditPage, Repeater, SelectField, TextField } from '@contember/admin'
import * as React from 'react'

export const MenuPage = (
	<EditPage
		pageName="menu"
		entity="Site(slug = $site)"
		rendererProps={{
			title: 'Menu',
		}}
	>
		<Repeater sortableBy="order" field="menu.items" removalType="delete" label={undefined}>
			<TextField field="caption" label="Caption" />
			<SelectField field="link" label="link" options="Linkable.url" />
			<CheckboxField field="showCategories" label="Show categories" />
		</Repeater>
	</EditPage>
)
