import {
	Box,
	Component,
	TextField,
	Repeater,
	Field,
	useRelativeEntityList,
	FieldAccessor,
	EntityAccessor,
	EntityForRemovalAccessor,
	useRelativeSingleField,
	Button,
	TextInput,
	CheckboxField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField, IsPublishedField, LocationField } from '../components'
import { SeoForm } from './'
import { useCallback, useState } from 'react'

const voucherChars = '0123456789'
const generateVoucherCode = (usedCodes: string[]): string => {
	let length = 4
	let code = ''
	let attempts = 0
	do {
		code = ''
		const voucherCharsCount = voucherChars.length
		for (let i = 0; i < length; i++) {
			const index = Math.floor(Math.random() * voucherCharsCount)
			code += voucherChars.charAt(index)
		}
		if (attempts++ > 20) {
			attempts = 0
			length++
		}
		code = 'PU' + code
	} while (usedCodes.includes(code))

	return code
}

const getVoucherCode = (it: EntityAccessor | EntityForRemovalAccessor | undefined) => {
	if (!it) {
		throw new Error()
	}
	const field = it?.getField('code')
	if (!(field instanceof FieldAccessor)) {
		throw new Error()
	}
	return String(field.currentValue)
}

const saveFile = (file: string, filename: string) => {
	const blob = new Blob([file], { type: 'text/plain;charset=utf-8;' })
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename)
	} else {
		const link = document.createElement('a')
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob)
			link.setAttribute('href', url)
			link.setAttribute('download', filename)
			link.style.visibility = 'hidden'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}
}

export const GenerateVouchers = Component(
	() => {
		const pubName = useRelativeSingleField('name')
		const entity = useRelativeEntityList('vouchers')
		const [count, setCount] = useState(100)
		const generateVouchers = useCallback(() => {
			if (!entity.batchUpdates) {
				return
			}
			entity.batchUpdates(getAccessor => {
				const usedCodes = getAccessor().entities.map(getVoucherCode)
				for (let i = 0; i < count; i++) {
					const accessor = getAccessor()
					if (!accessor.addNew) {
						return
					}
					accessor.addNew((getAccessor, newIndex) => {
						const newEntity = getAccessor().entities[newIndex]
						if (!newEntity) {
							return
						}
						const field = newEntity.getField('code')
						if (!(field instanceof FieldAccessor)) {
							throw new Error()
						}
						if (!field.updateValue) {
							return
						}
						const code = generateVoucherCode(usedCodes)
						usedCodes.push(code)
						field.updateValue(code)
					})
				}
			})
		}, [count, entity])
		const downloadVouchers = useCallback(() => {
			const vouchers = entity.entities.map(getVoucherCode).join('\n')
			const fileName = `vouchers ` + String(pubName.currentValue) + '.txt'
			saveFile(vouchers, fileName)
		}, [entity.entities, pubName.currentValue])

		return (
			<Box>
				<Button onClick={downloadVouchers}>Download vouchers</Button>
				<br />
				<br />
				<div>
					<TextInput type={'number'} onChange={e => setCount(Number(e.target.value))} value={String(count)} />
					<Button onClick={generateVouchers}>Generate vouchers</Button>
				</div>
			</Box>
		)
	},
	() => <></>,
	'GenerateVouchers',
)

export const PromoPubForm = Component(
	() => (
		<>
			<Box>
				<TextField field="name" label="Name" size="large" />
				<TextField field="subtitle" label="Subtitle" />
				<CheckboxField field="hasFreeVouchers" label="Has free vouchers" />
				<TextField field="link.url" label={'URL'} />
			</Box>

			<Box>
				<ImageField field="listingImage" label="Listing image" />
			</Box>

			<Box heading="Location">
				<TextField field="locationText" label="Text" />
				<LocationField field="location" label="Location" />
			</Box>
			<Box>
				<TextField field="websiteUrl" label="Website" />
				<TextField field="facebookUrl" label="Facebook" />
				<TextField field="instagramUrl" label="Instagram" />
			</Box>

			<SeoForm />

			<Repeater field={'vouchers'} label={'Vouchers'} initialRowCount={0}>
				<TextField field={'code'} label={'Code'} />
				<Field
					field="usage.createdAt"
					format={val => {
						return val ? 'Sent on ' + new Date(val as string).toLocaleDateString() : 'Not sent yet'
					}}
				/>
			</Repeater>
			<GenerateVouchers />
		</>
	),
	'PromoPubForm',
)

export const PromoPubFormSide = Component(() => <IsPublishedField />, 'PubFormSide')
