import { Field, PageLinkButton, TableCell, TablePage } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../components'

export const TagPage = (
	<TablePage
		pageName="tags"
		entities="Tag[site.slug = $site]"
		orderBy="name asc"
		rendererProps={{
			title: 'Tags',
			actions: <PageLinkButton to="tagCreate">Add a new tag</PageLinkButton>,
		}}
	>
		<TableCell>
			<Field field="name" />
		</TableCell>
		<TableCell shrunk>
			<EditButton pageName="tagEdit" />
		</TableCell>
	</TablePage>
)
