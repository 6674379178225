import { Box, EditPage, HasOne, TextField, Repeater, TextAreaField } from '@contember/admin'
import * as React from 'react'
import { ImageField } from '../components'
import { FrontPageContentForm, LinkForm, SeoForm } from '../forms'

export const FrontPage = (
	<EditPage pageName="frontPage" entity="Site(slug = $site)" rendererProps={{ title: 'Front page' }}>
		<HasOne field="frontPage">
			<Box>
				<TextField field="title" label="Title" size="large" allowNewlines={true} />
			</Box>
			<ImageField field="headerImage" label="Header image" />
			<Repeater sortableBy="order" field="images" removalType="delete" label={undefined}>
				<TextAreaField field="caption" label="Caption" />
				<ImageField field="image" label="Image" />
			</Repeater>

			<FrontPageContentForm />
			<Box>
				<TextField field="scrollString" label="Scroll string" allowNewlines={true} />

				<LinkForm />
			</Box>
			<SeoForm />
		</HasOne>
	</EditPage>
)
