import { Field, ListPage, PageLinkButton } from '@contember/admin'
import * as React from 'react'
import { EditButton, LocationTreeContainer } from '../components'

const locationBody = (
	<>
		<Field field="name" />
		<Field field="parent.name" format={value => (value ? `, ${value}` : null)} />
		<EditButton pageName="locationEdit" style={{ marginLeft: '.75em' }} />
	</>
)

export const LocationPage = (
	<ListPage
		pageName="locations"
		entities="Location[site.slug = $site]"
		rendererProps={{
			title: 'Locations',
			actions: <PageLinkButton to="locationCreate">Add a new location</PageLinkButton>,
			containerComponent: LocationTreeContainer,
			containerComponentExtraProps: {
				locationBody,
			},
		}}
	>
		{locationBody}
	</ListPage>
)
