import { DetailPage, PageLinkButton } from '@contember/admin'
import * as React from 'react'
import { GenericPageList } from './GenericPageList'

// TODO use a DetailPage
export const GenericPageListPage = (
	<DetailPage
		pageName="pageList"
		entity="Site(slug = $site)"
		rendererProps={{ title: 'Pages', actions: <PageLinkButton to="pageCreate">Add a new page</PageLinkButton> }}
	>
		<GenericPageList />
	</DetailPage>
)
