import { Box, Component, TextField } from '@contember/admin'
import * as React from 'react'
import { LocationField } from '../components'

export const LocationForm = Component(
	prop => (
		<Box>
			<TextField field="name" label="Name" size="large" />
			<TextField field="slug" label="Slug" />
			<TextField field="altName" label="Alternative name" />
			<LocationField field="parent" label="Parent" />
		</Box>
	),
	'LocationForm',
)
