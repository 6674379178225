import { MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'

export const SitesPage = (
	<MultiEditPage
		entities="Site"
		pageName="sites"
		rendererProps={{
			title: 'Sites',
			sortableBy: 'order',
		}}
	>
		<TextField label="Name" field="name" />
		<TextField label="Slug" field="slug" />
	</MultiEditPage>
)
