import { EditPage, NavigateBackButton } from '@contember/admin'
import * as React from 'react'
import { TapsterForm, TapsterFormSide } from '../forms'

export const TapsterEditPage = (
	<EditPage
		entity="Tapster(id = $id)"
		pageName="tapsterEdit"
		rendererProps={{
			title: 'Edit tapster',
			side: <TapsterFormSide />,
			navigation: <NavigateBackButton to="tapsterList">Tapsters</NavigateBackButton>,
		}}
	>
		<TapsterForm />
	</EditPage>
)
